import React, { Fragment } from 'react';
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../components/css/style.css';

export const xAuth="M@#weh@##dhASD2627@#2024";

/* ############ Botswana service ############# 
export const service="bwa";
export const countryCode="232";
export const flag="bw";
export const lang="en";
export const serviceId=1;
export const baseUrl = "/bwaapi"; */
//export const baseUrl = "https://botswana.uvcanngaming.com/bwaapi"; 

/* ############ Liberia service ########## 
export const service="lba";
export const countryCode="231";
export const flag="lr";
export const lang="en";
export const serviceId=2;
//export const baseUrl = "/lbaapi";
export const baseUrl = "https://lba.uvcanngaming.com/lbaapi";*/

/* ############ SL service ########## 
export const service="sl";
export const countryCode="260";
export const flag="cm";
export const lang="en";
export const serviceId=2;
export const baseUrl = "/slapi";
//export const baseUrl = "https://osl.uvcanngaming.com/slapi";*/


/* ############ mtnz service ########## */
export const service="mtnz";
export const countryCode="260";
export const flag="zm";
export const lang="en";
export const serviceId=1;
//export const baseUrl = "/slapi";
export const baseUrl = "https://mtnz.uvcanngaming.com/mtnzApi"; 




export const brandImages = [
    "/images/brand_1.png",
    "/images/brand_2.png",
    "/images/brand_3.png",
    "/images/brand_4.png",
    "/images/brand_2.png",
    "/images/brand_5.png",
  ];

export const footerImages = [
      "/images/footer_3.png",
      "/images/footer_2.png",
      "/images/footer_1.png",
      "/images/footer_4.png",
      "/images/footer_5.png",
  ];

 
  export const Loader = () => {
    return (
      <div style={{ width: "100px", margin: "auto",}}>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="60"
          visible={true}
        />
      </div>
    )  
  
  }
  export const Notify = (props) => {
    console.log('props='+props);
    if(props.message)
    {
      if(props.type)
        toast.success(props.message,);
      else
        toast.warning(props.message,);
    }
    // inbuilt-notification
   /* toast.success("successful");
    // inbuilt-notification
    toast.info("GeeksForGeeks");
    // inbuilt-notification
    toast.error("Runtime error");
    // default notification
    toast("Hello Geeks");*/

    return (
      <ToastContainer />
    );
  }
